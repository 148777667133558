import AddDevice from "../controls/AddDevice";
import AddRoom from "../controls/AddRoom";
import DeviceListContainer from "./DeviceListContainer";
import {observer} from "mobx-react-lite";
import {useContext, useEffect, useState} from "react";
import RoomStore from "../store/RoomStore";
import {RoomDTO} from "../../api/types";

const  maintenanceRoom:RoomDTO = {id: -2, name: "Maintenance"};

function Configuration() {
    const roomStore = useContext(RoomStore);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        roomStore.selectedRoom = maintenanceRoom;
        roomStore.loadClientList().then(() => {
            roomStore.loadDeviceInfo().then(() => {
                setLoading(false);
            });
        })
    }, [roomStore]);

    return (
        <div className="server">
            {!loading && <>
                <div className="container">
                    <div className="server__forms">
                        <div className="row flex-wrap">
                            <div className="col col-6">
                                <AddDevice/>
                            </div>
                            <div className="col col-6">
                                <AddRoom/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <DeviceListContainer/>
                </div>
            </>
            }
        </div>
    )
}

export default observer(Configuration);