import styled from "styled-components";

export const Thumb = styled.div`
  /* This renders the buttons above... Edit me! */
  position: absolute;
  z-index: 2;
  width: 50px;
  height: 25px;
  cursor: pointer;
  -ms-touch-action: none;
  touch-action: none;
  outline: 0;
  border-radius: 56px !important;
  bottom: auto;
  top: -7px;
  left: -20px;
`

export const SlideValue = styled.div`
  /* This renders the buttons above... Edit me! */
  position: relative;
  bottom: 20px;
`

export const EditableInput = styled.input`
    width: 75%;
`


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%; /* Adjust width as needed */
    border: 3px solid rgba(95, 133, 254, .36);
    border-radius: 10px;
    padding-top: 10px;
    padding-left: 5px;
    padding-bottom: 5px;
`;

export const Line1 = styled.div`
  display: flex;
  width: 100%;
    margin-bottom: 5px;
`;

export const Line2 = styled.div`
    display: flex;
    justify-content: space-around; /* Equally space buttons */
    width: 100%;
   
`;

export  const InputField = styled.input`
  flex: 1; /* Makes the input field flexible */
  border: 1px solid #ccc;
  padding: 5px;
  margin-right: 10px; /* Space between input and buttons */
`;

export const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  //background-color: #4CAF50; /* Change button color as desired */
  //color: white;
`;

export const FourButtonContainer = styled.div`
  display: flex;
  justify-content: space-around; /* Equally space buttons */
  width: 100%;
`;
