import {observer} from "mobx-react-lite";
import React, {useContext, useState} from "react";
import RoomStore from "../store/RoomStore";
import {ClientDeviceDTO, DeviceInfoDTO, PWDUserUpdateDTO, RegistrationDTO} from "../../api/types";
import AuthStore from "../store/AuthStore";
import ErrorField from "../errors/errorField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

function ChangePassword() {

    const [changeRequest, setChangeRequest] = useState<PWDUserUpdateDTO>({
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    });
    const [error, setError] = useState(false);
    const [processing, setProcessing] = useState<boolean>(false);
    const authStore = useContext(AuthStore);

    function setValue(value: string, field: string) {
        if (value) {
            if (field === "oldPassword") {
                changeRequest.oldPassword = value;
            } else if (field === "newPassword") {
                changeRequest.newPassword = value;
            } else if (field === "confirmPassword") {
                changeRequest.confirmPassword = value;
            }
            setChangeRequest(changeRequest);
        }
    }

    function handleSubmit(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        console.log("Save event")
        if (!changeRequest.oldPassword || !changeRequest.newPassword || !changeRequest.confirmPassword || changeRequest.newPassword !== changeRequest.confirmPassword) {
            setError(true);
            console.log(error);
            return;
        }
        setProcessing(true);
        let promise = authStore.updatePassword(changeRequest);
        promise.then(() => {
            setError(false);
            setProcessing(false);
        }).catch(() => {
            setError(true);
            setProcessing(false);
        })

    }


    return (
        <div className="card__body">
            <div className="form">
                <div className="row flex-wrap">
                    <div className="col col-12">
                        <div className="form__title">Change Password</div>
                    </div>

                    <div className="col col-5">
                        <div className="group h40">
                            <input type="password" placeholder="Old Password"
                                   onChange={(event) => setValue(event.target.value, "oldPassword")}/>
                        </div>
                    </div>
                    <div style={{width: "100%", marginBottom: "5px"}}></div>
                    <div className="col col-5">
                        <div className="group h40">
                            <input type="password" placeholder="New Password"
                                   onChange={(event) => setValue(event.target.value, "newPassword")}/>
                        </div>
                    </div>
                    <div className="col col-5">
                        <div className="group h40">
                            <input type="password" placeholder="Re-type New Password"
                                   onChange={(event) => setValue(event.target.value, "confirmPassword")}/>
                        </div>
                    </div>
                    <div className="col col-2">
                        {!processing &&
                            <button className="btn btn-primary" onClick={handleSubmit}>Change</button>
                        }
                        {processing &&
                            <FontAwesomeIcon icon={solid("spinner")}/>
                        }
                    </div>
                    {error ?
                        <div className="col col-5">
                            <div className={`group h40 mb8 has-error`}>
                                <strong className="invalid-feedback">Check Input fields</strong>
                            </div>
                        </div> : null}

                </div>
            </div>
        </div>
    )
}

export default observer(ChangePassword);