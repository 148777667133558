import {action, computed, makeObservable, observable, runInAction} from "mobx"
import {createContext} from "react"
import {LoginDetails, LoginResponse, PWDUserUpdateDTO, RegistrationDTO} from "../../api/types";
import httpService from "../../api/client";
import AuthToken from "./AuthToken";
import {SessionStore} from "./SessionStore";
import {clearInterval, setInterval} from 'worker-timers';

class AuthStore {


    @observable errorAuth: boolean = false;
    @observable.ref authToken: AuthToken | null = null;
    sessionStore = new SessionStore();
    interval:number|undefined;

    constructor() {
        this.reloadToken();
        if (this.authToken && !this.authToken?.refreshTokenExpired) {
            this.refreshToken();
        }
        if (!this.authToken || this.authToken.refreshTokenExpired) {
            this.authToken = null;
        }
        if (this.authToken) {
            this.setTokenRefresh();
        }
        makeObservable(this);

    }

    private setTokenRefresh() {
        if (!this.interval) {
            this.interval = setInterval(() => {
                this.refreshToken();
            }, 1200000);
        }
    }

    refreshToken() {
        if (this.authToken && !this.authToken?.refreshTokenExpired) {
            httpService.refreshToken({refreshToken: this.authToken.refresh})
                .then(loginResponse => {
                    this.setToken(loginResponse);
                })
        } else {
            this.clearSession();
        }
    }

    @computed get token() {
        return this.authToken;
    }

    @computed get isLoggedIn() {
        return !!this.authToken;
    }

    @action setToken(authToken: LoginResponse | null) {
        if (authToken) {
            this.sessionStore.writeToken(authToken);
            this.authToken = this.sessionStore.readToken();
        }
    }

    clearSession() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
        }
        this.sessionStore.clearToken();
        runInAction(() => {
                this.authToken = null;
            }
        )

    }

    @action reloadToken() {
        this.authToken = this.sessionStore.readToken();
    }


    @action login = async (loginRequest: LoginDetails) => {
        let loginResponse = await httpService.login(loginRequest);
        if (loginResponse !== undefined) {
            runInAction(() => {
                this.setToken(loginResponse);
                this.errorAuth = false;
                this.setTokenRefresh();
            });
        }
        return loginResponse;
    }


    @action register = async (createRequest: RegistrationDTO) => {
        return await httpService.register(createRequest);
    };

    @action updatePassword = async (updateRequest: PWDUserUpdateDTO) => {
        return await httpService.passwordChange(updateRequest);
    };



}

export default createContext(new AuthStore())