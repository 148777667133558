import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

function UserIcon() {

    return (
        <div className="header__user" >
            <Link to="user" className="d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={solid('user')}/>
            </Link>
        </div>
    )
};

export default UserIcon;