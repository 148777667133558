import {action, makeObservable, observable, runInAction} from "mobx";
import {createContext} from "react";
import {
    AvailableColorsDTO,
    ClientDeviceDTO, compareRoomsByOrder,
    ComponentLineDTO,
    DeviceInfoDTO, KeyMultuplieValue,
    RoomDTO,
    TimerRequestDTO,
    TimerUpdateDTO
} from "../../api/types";
import httpService from "../../api/client";

class RoomStore {

    @observable roomList: RoomDTO[] = [];
    @observable deviceList: DeviceInfoDTO[] = [];
    @observable clientDeviceList: ClientDeviceDTO[] = [];
    @observable availableColors: AvailableColorsDTO[] = [];
    @observable selectedRoom: RoomDTO | undefined = undefined;
    @observable selectedRoomID: number | undefined = undefined;
    @observable.deep listOfDevices: ComponentLineDTO[] = [];


    constructor() {
        makeObservable(this);
    }

    @action resortRoomList = () => {
        this.roomList = this.roomList.sort(compareRoomsByOrder);
        console.log(this.roomList)
    }

    @action loadRooms = async () => {
        console.log("Selected room ID is:", this.selectedRoomID);
        if (this.roomList.length <= 0) {
            await httpService.getAllRoom().then((roomList) => {
                runInAction(() => {
                    if (roomList) {
                        this.resortRoomList();
                        this.roomList = roomList;
                        if (this.selectedRoom === undefined) {
                            this.selectedRoom = this.selectDefaultRoom(roomList);
                            this.loadRoomLines();
                        }
                    }
                });
            });
        } else {
            return new Promise((resolve) => {
                if (this.selectedRoom === undefined) {
                    this.selectedRoom = this.selectDefaultRoom(this.roomList);
                }
                resolve(this.roomList);
            })
        }
    }

    private selectDefaultRoom(roomList: RoomDTO[]) {
        if (this.selectedRoomID) {
            let find = roomList.find((room) => room.id === this.selectedRoomID);
            if (find) {
                return find;
            }
        }
        return roomList[0];
    }

    @action loadDeviceInfo = async () => {
        await httpService.getAllDeviceInfoList().then((list) => {
            runInAction(() => {
                if (list) {
                    this.deviceList = list;
                }
            })
        });
    }


    @action loadAvailableColors = async () => {
        await httpService.getAvailableColors().then((list) => {
            runInAction(() => {
                if (list) {
                    this.availableColors = list;
                }
            })
        });
    }

    @action saveNewColor = async (color: AvailableColorsDTO) => {
        await httpService.saveAvailableColor(color).then((list) => {
            runInAction(() => {
                let colorReplace = this.availableColors.find(r => r.mac === color.mac && r.colorName === color.colorName);
                if (colorReplace) {
                    let updatedIndex = this.availableColors.findIndex((p) => p.mac === color.mac && p.colorName === color.colorName);
                    this.availableColors[updatedIndex] = color;
                } else {
                    this.availableColors.push(color);
                }
            })
        });
    }


    getDeviceInfo = (id: number | undefined) => {
        if (id) {
            let deviceInfoDTO = this.deviceList.find(room => room.id === id);
            if (deviceInfoDTO) {
                return deviceInfoDTO.name;
            }
        }
        return "";
    }

    getDeviceGroups = async (id: number) => {
        return await httpService.getDeviceValues(id)
    }

    @action setSelectedRoomNumber(id: number) {
        this.selectedRoomID = id;
    }

    @action addRoom = async (room: RoomDTO) => {
        await httpService.addNewRoom(room).then((result) => {
            runInAction(() => {
                this.roomList.push(result);
            })
        }).catch((error) => {
            console.log(error);
        })
    }

    @action removeRoom = async (id: number) => {
        await httpService.deleteRoom(id).then(() => {
            runInAction(() => {
                this.roomList = this.roomList.filter(room => room.id !== id);
            })
        }).catch(function (error) {
            console.debug(error);
        })

    }

    @action updateRoom = async (id: number, room: RoomDTO) => {
        await httpService.updateRoom(id, room).then(() => {
            runInAction(() => {
                this.roomList = this.roomList.filter(room => room.id !== id);
            })
        }).catch(function (error) {
            console.debug(error);
        })
    }

    @action addNewDevice = async (request: ClientDeviceDTO) => {
        await httpService.addNeDeviceToClient(request).then((deviceInfo: ClientDeviceDTO) => {
                runInAction(() => {
                    this.clientDeviceList.push(deviceInfo);
                })
            }
        );
    }


    @action loadClientList = async () => {
        await httpService.getAllClientDevice().then((devices: ClientDeviceDTO[]) => {
                runInAction(() => {
                    this.clientDeviceList = devices;
                })
            }
        );
    }

    @action updateClientDevice = async (id: number, info: ClientDeviceDTO) => {
        await httpService.updateDevice(id, info).then((device: ClientDeviceDTO) => {
                runInAction(() => {
                    let updatedIndex = this.clientDeviceList.findIndex((p) => p.id === id);
                    this.clientDeviceList.splice(updatedIndex, 1, device)
                })
            }
        );
    }

    @action updateCommandData = async (id: number, lineData: ComponentLineDTO) => {
        await httpService.saveCommandValues(lineData, id).then((line: ComponentLineDTO) => {
            }
        );
    }

    @action updateManagementData = async (id: number, lineData: ComponentLineDTO) => {
        await httpService.saveManagementValues(lineData, id);
    }

    @action updateManagementDataJson = async (id: number, lineData: KeyMultuplieValue) => {
        await httpService.saveManagementValuesJson(lineData, id);
    }

    loadRoomLines = async () => {
        if (this.selectedRoom !== undefined) {
            return await httpService.loadRoomDevices(this.selectedRoom.id).then((lines: ComponentLineDTO[]) => {
                    runInAction(() => {
                        this.listOfDevices = lines;
                    })
                }
            );
        }
    }

    updateRoomLines = async () => {
        if (this.selectedRoom !== undefined) {
            return await httpService.loadRoomChanges(this.selectedRoom.id).then((lines: ComponentLineDTO[]) => {
                if (lines.length > 0) {
                    console.log("updated lines:", lines)
                }
                runInAction(() => {
                    lines.forEach((line) => {
                        runInAction(() => {
                            let componentLine = this.listOfDevices.find(r => r.id === line.id);
                            if (componentLine) {
                                let updatedIndex = this.listOfDevices.findIndex((p) => p.id === line.id);
                                this.listOfDevices[updatedIndex] = line;
                            }
                        })

                    })
                });
            })
        }
    }


    @action
    deleteClientDevice = async (id: number) => {
        await httpService.deleteClientDevice(id).then(() => {
                runInAction(() => {
                    this.clientDeviceList = this.clientDeviceList.filter(device => device.id !== id)
                })
            }
        );
    }

    updateTimerValue = async (request: TimerUpdateDTO) => {
        await httpService.updateTimer(request);
    }

    getTimerValues = async (request: TimerRequestDTO) => {
        return await httpService.requestTimer(request);
    }

    @action
    clearData() {
        this.roomList = [];
        this.deviceList = [];
        this.clientDeviceList = [];
        this.selectedRoom = undefined;
        this.listOfDevices = [];
    }
}

export default createContext(
    new RoomStore()
);