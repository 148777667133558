import {observer} from "mobx-react-lite";
import React, {useContext, useEffect, useState} from "react";
import RoomStore from "../store/RoomStore";
import SelectorMenu from "./SelectorMenu";
import {RoomDTO} from "../../api/types";
import {runInAction} from "mobx";
import {useNavigate} from "react-router-dom";

function RoomSelector() {

    const roomStore = useContext(RoomStore);
    const [toggle, setToggle] = useState(false);
    const navigate = useNavigate();

    function changeCurrentRoom(selectedRoom: RoomDTO) {
        runInAction(() => {
            roomStore.selectedRoom = selectedRoom;
            navigate("/room", {replace: true});
        })
    }

    useEffect(() => {
        roomStore.loadRooms();
    }, [roomStore]);

    return (
        <div className={"header__nav " + (toggle ? "is-active" : '')} onClick={() => setToggle(!toggle)}>
            <div className="header__nav--toggle">
                <span>{roomStore.selectedRoom?.name}</span>
                <i className="header__nav--icon icon-angle-down"/>
            </div>

            <div className="header__nav-menu" style={{height: "50vh", overflow: "auto"}}>
                <ul className="header__nav-menu--main">
                    {roomStore.roomList.map(room =>
                        (
                            <li onClick={() => changeCurrentRoom(room)} key={room.id}>
                                <a className={roomStore.selectedRoom?.id === room.id ? "is-active" : ""}>{room.name}</a>
                            </li>
                        )
                    )
                    }
                </ul>
                <SelectorMenu/>

            </div>
        </div>);
}

export default observer(RoomSelector);