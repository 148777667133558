import {Link, useNavigate} from "react-router-dom";
import React, {useContext, useState} from "react";
import {RegistrationDTO} from "../../api/types";
import AuthStore from "../store/AuthStore";


function UserRegistration() {

    const [regRequest, setRequest] = useState<RegistrationDTO>({name: "", password: "", retypePassword: ""});
    const [error, setError] = useState(false);
    const authStore = useContext(AuthStore);
    const navigate = useNavigate();

    function setValue(value: string, field: string) {
        if (value) {
            if (field === "password") {
                regRequest.password = value;
            } else if (field === "login") {
                regRequest.name = value;
            } else if (field === "retype") {
                regRequest.retypePassword = value;
            }
            setRequest(regRequest);
        }
    }

    function handleSubmit(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        if (!regRequest.password || !regRequest.name || !regRequest.retypePassword || regRequest.retypePassword !== regRequest.password) {
            setError(true);
            console.log(error);
            return;
        }
        console.log(regRequest);
        let promise = authStore.register(regRequest);
        promise.then(() => {
            navigate("/", {replace: true});
        }).catch(() => {
            console.log("error");
        })

    }

    return (
        <div className="auth">
            <div className="container d-flex align-items-center justify-content-center">
                <div className="row w100 justify-content-center">
                    <div className="col col-5">
                        <div className="card">
                            <div className="card__header">
                                <h3>Registration</h3>
                            </div>

                            <div className="card__body">
                                <form action="" className="form mb8">
                                    <div className="group h40 mb8">
                                        <input type="text" placeholder="Login"
                                               onChange={(event) => setValue(event.target.value, "login")}/>
                                    </div>

                                    <div className="group h40 mb8">
                                        <input type="password" placeholder="Password"
                                               onChange={(event) => setValue(event.target.value, "password")}/>
                                    </div>

                                    <div className="group h40 mb8">
                                        <input type="password" placeholder="Reenter password"
                                               onChange={(event) => setValue(event.target.value, "retype")}/>
                                    </div>


                                    <button className="btn btn-primary" onClick={handleSubmit}>Add User
                                    </button>
                                </form>

                                <Link to="/" className="btn btn-secondary">Main Page</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default UserRegistration;