import {observer} from "mobx-react-lite";
import React, {useContext, useState} from "react";
import RoomStore from "../store/RoomStore";
import {ClientDeviceDTO, DeviceInfoDTO} from "../../api/types";

function AddDevice() {

    const roomStore = useContext(RoomStore);
    const [toggled, setToggled] = useState(false);
    const [deviceListDrop, setDeviceListDrop] = useState(false);
    let deviceInitialState = {id: -1, name: ""};
    const [selectedDevice, setSelectedDevice] = useState<DeviceInfoDTO>(deviceInitialState);
    let initialState = {
        id: undefined,
        name: "",
        knx: "",
        mac: "",
        deviceType: -1,
        update:"",
        modelst:"",
        orderNumber:0
    };
    const [clientDeviceInfo, setClientDeviceInfo] = useState<ClientDeviceDTO>(initialState);

    function setValue(value: string, field: string) {
        setClientDeviceInfo({...clientDeviceInfo, [field]: value})
    }

    function createNewDevice(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        if (selectedDevice.id === -1) {
            return;
        } else if (!clientDeviceInfo.name || !clientDeviceInfo.knx || !clientDeviceInfo.mac) {
            return;
        }
        clientDeviceInfo.deviceType = selectedDevice.id;
        setClientDeviceInfo(clientDeviceInfo)
        roomStore.addNewDevice(clientDeviceInfo).then(() => {
            setClientDeviceInfo(initialState);
            setSelectedDevice(deviceInitialState);
        }).catch((error) => {
            console.log(error)
        });
    }

    function toggleComponent() {
        setToggled(!toggled);
        setDeviceListDrop(false);
    }

    function toggleForm(){
        if (!toggled){
            roomStore.loadDeviceInfo().then((result)=>{
                toggleComponent();
            });
        } else {
            toggleComponent();
        }
    }

    return (
        <div className="card">
            <div className={`card__header card__header--toggle ${toggled ? "is-open" : ""}`}
                 onClick={() => {
                     toggleForm();
                 }}>
                <h3>Create hub</h3>
                <i className="icon-angle-down"></i>
            </div>
            <div className="card__body">
                <form action="" className="form mb8">
                    <div className="group mb8 h40">
                        <div className={`selectWrapper ${deviceListDrop ? "is-active" : ""}`}
                             onClick={() => setDeviceListDrop(!deviceListDrop)}>
                            <div className="selectCustom h40 js-selectCustom">
                                <div className="selectCustom-trigger">{selectedDevice.name}</div>
                                <div className="selectCustom-options">
                                    {roomStore.deviceList.map(device =>
                                        (<div key={"key" + device.id} className="selectCustom-option"
                                              data-value={device.id}
                                              onClick={() => setSelectedDevice(device)}>{device.name}</div>)
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="group h40 mb8">
                        <input type="text" placeholder="MAC" onChange={(event) => setValue(event.target.value, "mac")}
                               value={clientDeviceInfo.mac}/>
                    </div>

                    <div className="group h40 mb8">
                        <input type="text" placeholder="KNX" onChange={(event) => setValue(event.target.value, "knx")}
                               value={clientDeviceInfo.knx}/>
                    </div>

                    <div className="group h40 mb8">
                        <input type="text" placeholder="Device Name(custom)"
                               onChange={(event) => setValue(event.target.value, "name")}
                               value={clientDeviceInfo.name}/>
                    </div>

                    <button className="btn btn-primary" onClick={createNewDevice}>Add</button>
                </form>
            </div>

        </div>
    )
}

export default observer(AddDevice);