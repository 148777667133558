import logo from "../../img/logo.png"
import {Link, useNavigate} from "react-router-dom";
import React, {useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import AuthStore from "../store/AuthStore";
import RoomStore from "../store/RoomStore";


function Logo() {

    const navigate = useNavigate();
    const authStore = useContext(AuthStore)
    const roomStore = useContext(RoomStore)

    function logout(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        authStore.clearSession();
        roomStore.clearData();
        navigate("/", {replace: true});
    }


    return (
        <div className="header__logo">
            <Link to="/"  onClick={(event) => logout(event)}>
                <img src={logo} alt="Rex"/>
            </Link>
        </div>
);
}

export default Logo;