import {Link} from "react-router-dom";

function SelectorMenu() {


    return (
        <ul className="header__nav-menu--sub d-flex flex-wrap">
            <li><a role="button"
                   className="d-flex align-items-center justify-content-center flex-wrap"><i
                className="icon-cold"></i></a></li>
            <li><a role="button" className="d-flex align-items-center justify-content-center flex-wrap"><i
                className="icon-hot"></i></a></li>
            <li><a role="button" className="d-flex align-items-center justify-content-center flex-wrap"><i
                className="icon-curtain"></i></a></li>
            <li><a role="button" className="d-flex align-items-center justify-content-center flex-wrap"><i
                className="icon-lamp"></i></a></li>
            <li>
                <Link to="configuration" className="d-flex align-items-center justify-content-center flex-wrap">
                    <i className="icon-setting"></i>
                </Link>
            </li>
        </ul>);
}

export default SelectorMenu;