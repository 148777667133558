import Logo from "../controls/Logo";
import RoomSelector from "../controls/RoomSelector";
import {useContext} from "react";
import AuthStore from "../store/AuthStore";
import UserIcon from "../controls/UserIcon";
import {observer} from "mobx-react-lite";

function HeaderContainer() {
    const authStore = useContext(AuthStore)
    return (
        <>
            <header className="header" >
                <div className="container  d-flex align-items-center justify-content-between flex-wrap">

                    <Logo/>
                    {authStore.isLoggedIn &&
                        <>
                            <RoomSelector/>
                            <UserIcon/>
                        </>
                    }
                </div>
            </header>
            <div className="header__fake"></div>
        </>
    )
}

export default observer(HeaderContainer);