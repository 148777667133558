export interface LoginDetails {
    userName: string;
    password: string;
}

export interface LoginResponse {
    expireDate: string;
    jwtToken: string;
    refreshTokenExpireDate: string;
    refreshToken: string;
}

export interface RegistrationDTO {
    name: string;
    password: string;
    retypePassword: string;
}

export interface PWDUserUpdateDTO {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export interface RoomDTO {
    id: number;
    name: string;
    order?: number;
}

export interface DeviceInfoDTO {
    id: number;
    name: string;
}

export interface ClientDeviceDTO {

    id: number | undefined;
    mac: string;
    knx: string;
    name: string;
    deviceType: number;
    update: string;
    modelst: string;
    orderNumber:number;
}

export interface KeyValue {
    topicName: string;
    value: string | undefined;
}

export interface KeyMultuplieValue {
    topicName: string;
    value: { [key: string]: number | string } | null;
}

export interface ComponentLineDTO {
    id: number;
    abbreviation: string;
    name: string;
    order: number;
    roomID: number;
    type: string;
    mac: string;
    topics: KeyValue[];
    specialComponentTopics: KeyValue[];
}

export interface ComponentGroupDTO {

    id: number;
    groupName: string;
    groupAbbreviation: string;
    color: string;
    multiline: boolean;
    lines: ComponentLineDTO[];
}

export interface RefreshRequest {
    refreshToken: string;
}

export interface TimerUpdateDTO {
    id: number;
    topic: string;
    value: string;
}

export interface TimerRequestDTO {
    id: number;
    topic: string;
}

export interface FirmwareRequestDTO {
    id: number;
    version: string;
}

export interface AvailableColorsDTO {
    mac: string;
    colorName: string;
    colorValue: string;
}

export const compareRoomsByOrder = (a: RoomDTO, b: RoomDTO) => {
    const orderA = a.order ?? 0;
    const orderB = b.order ?? 0;
    return orderA - orderB;
};