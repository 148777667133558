import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import LoginContainer from "./components/containers/Login";
import {Navigate, Route, Routes} from "react-router-dom";
import UserRegistration from "./components/containers/UserRegister";
import Cabinet from "./components/containers/Cabinet";
import AuthStore from "./components/store/AuthStore";
import {observer} from "mobx-react-lite";
import HeaderComponent from "./components/containers/HeaderComponent";
import Configuration from "./components/containers/Configuration";
import RoomContainer from "./components/containers/RoomContainer";
import {runInAction, toJS} from "mobx";
import RoomStore from "./components/store/RoomStore";
import UserArea from "./components/containers/UserArea";

function App() {
    const authStore = useContext(AuthStore);
    const roomStore = useContext(RoomStore);
    const [loading, setLoaded] = useState(false);

    useEffect(() => {
        if (authStore.isLoggedIn && roomStore.selectedRoom === undefined) {
            setLoaded(true);
            console.log("executing fetch");
            roomStore.loadRooms().then(()=>{
                setLoaded(false);
            });
        }
    }, [authStore.isLoggedIn]);

    return (
        <div className="wrapper">
            {!loading && <>
                <HeaderComponent/>
                <Routes>
                    {authStore.isLoggedIn &&
                        <>
                            <Route path="/cabinet" element={<RoomContainer/>}/>
                            <Route path="/configuration" element={<Configuration/>}/>
                            <Route path="/user" element={<UserArea/>}/>
                            <Route path="/room" element={<RoomContainer/>}/>
                            <Route
                                path="*"
                                element={<Navigate to="/cabinet" replace/>}
                            />
                        </>
                    }
                    {!authStore.isLoggedIn &&
                        <>
                            <Route path="/" element={<LoginContainer/>}/>
                            <Route path="/registration" element={<UserRegistration/>}/>
                            <Route
                                path="*"
                                element={<Navigate to="/" replace/>}
                            />
                        </>
                    }
                </Routes>
            </>
            }
        </div>
    );
}

export default observer(App);
