import {observer} from "mobx-react-lite";
import React, {useContext, useState} from "react";
import RoomStore from "../store/RoomStore";
import {Button, Container, EditableInput, FourButtonContainer, InputField, Line1, Line2} from "./Common";
import {confirmAlert} from "react-confirm-alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {useCookies} from "react-cookie";

function AddRoom() {

    const roomStore = useContext(RoomStore);
    const [toggled, setToggled] = useState(false);
    const [roomName, setRoomName] = useState("");
    const [cookies, setCookie] = useCookies(['default-room'], {
        doNotParse: true,
    });

    function addNewRoom(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        if (roomName) {
            roomStore.addRoom({id: -1, name: roomName}).then(() => setRoomName(""));
        }
    }

    function changeRoomOrder(id: number, value: number, event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        let roomData = roomStore.roomList.find(room => room.id === id)
        if (roomData) {
            let order = roomData.order ?? 0;
            roomData.order = order + value;
            roomStore.resortRoomList();
        }
    }


    function toggleComponent() {
        setToggled(!toggled);
    }

    function toggleForm() {
        if (!toggled) {
            roomStore.loadRooms().then((result) => {
                toggleComponent();
            });
        } else {
            toggleComponent();
        }
    }

    function deleteRoom(id: number, event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        if (id <= 0) {
            return;
        }
        confirmAlert({
            message: 'Are You Sure that u want to delete room?',
            buttons: [
                {
                    label: 'No'
                },
                {
                    label: 'Yes',
                    onClick: () => {
                        roomStore.removeRoom(id);
                    }
                }
            ]
        });


    }

    function modifyRoomName(id: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        let roomName = event.target.value;
        let roomData = roomStore.roomList.find(room => room.id === id)
        if (roomName && roomData && roomData.name !== roomName) {
            roomData.name = roomName;
        }
    }

    function saveEditedRoom(id: number, event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        let roomData = roomStore.roomList.find(room => room.id === id)
        if (roomData) {
            roomStore.updateRoom(id, roomData);
        }
    }

    function saveCookie(id: number, event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        let date = new Date();
        date.setDate(date.getDate() + 368);
        setCookie("default-room", id, {expires: date});
    }

    console.log(cookies["default-room"]);
    return (
        <div className="card">
            <div className={`card__header card__header--toggle ${toggled ? "is-open" : ""}`}
                 onClick={toggleForm}>
                <h3>Create Room</h3>
                <i className="icon-angle-down"></i>
            </div>
            <div className="card__body">
                <form className="form mb8">
                    <div className="group h40 mb8">
                        <input type="text" placeholder="Enter Room Name" value={roomName}
                               onChange={(event) => setRoomName(event.target.value)}/>
                        <button style={{marginTop: "3px"}} className="btn btn-primary" onClick={addNewRoom}>Add</button>
                    </div>
                    {roomStore.roomList.map(room =>
                        (
                            <Container key={"key" + room.id}
                                       style={{borderColor: cookies["default-room"] != room.id ? undefined : "#0e1330"}}
                                       data-value={room.id} className="group group-with-btn р40 mb8">
                                <Line1>
                                    <InputField type="text" placeholder="Room Name" value={ room.name}
                                                onChange={(event) => modifyRoomName(room.id, event)}/>
                                </Line1>
                                <Line2>
                                    <FourButtonContainer>
                                        <Button
                                            className="server__forms-control d-flex align-items-center justify-content-center flex-wrap light-blue-border radius10"><i
                                            className="icon-save" onClick={(event) => {
                                            saveEditedRoom(room.id, event)
                                        }}></i></Button>
                                        <Button
                                            className="server__forms-control d-flex align-items-center justify-content-center flex-wrap light-blue-border radius10"
                                            onClick={(event) => {
                                                changeRoomOrder(room.id, -1, event)
                                            }}><FontAwesomeIcon icon={solid("arrow-up")}/></Button>
                                        <Button
                                            className="server__forms-control d-flex align-items-center justify-content-center flex-wrap light-blue-border radius10"
                                            onClick={(event) => {
                                                {
                                                    changeRoomOrder(room.id, 1, event)
                                                }
                                            }}>
                                            <FontAwesomeIcon icon={solid("arrow-down")}/></Button>
                                        <Button
                                            className="server__forms-control d-flex align-items-center justify-content-center flex-wrap light-blue-border radius10"
                                            onClick={(event) => deleteRoom(room.id, event)}>
                                            <i className="icon-remove"></i>
                                        </Button>
                                        <Button
                                            className="server__forms-control d-flex align-items-center justify-content-center flex-wrap light-blue-border radius10"
                                            onClick={(event) => saveCookie(room.id, event)}>
                                            <FontAwesomeIcon icon={solid("cookie")}/>
                                        </Button>
                                    </FourButtonContainer>
                                </Line2>
                            </Container>
                            //     <div className="group group-with-btn р40 mb8" key={"key" + room.id}
                            //       data-value={room.id}>
                            //     <input type="text" value={room.name}
                            //            onChange={(event) => modifyRoomName(room.id, event)}/>
                            //     <button
                            //         className="server__forms-control d-flex align-items-center justify-content-center flex-wrap light-blue-border radius10"
                            //         type="button"
                            //         onClick={(event) => {
                            //         }}>
                            //         <i className="icon-save"></i>
                            //     </button>
                            //     <button
                            //         className="server__forms-control d-flex align-items-center justify-content-center flex-wrap light-blue-border radius10"
                            //         type="button"
                            //         onClick={(event) => deleteRoom(room.id, event)}>
                            //         <i className="icon-remove"></i>
                            //     </button>
                            //     <button
                            //         className="server__forms-control d-flex align-items-center justify-content-center flex-wrap light-blue-border radius10"
                            //         type="button"
                            // >
                            //
                            //     </button>
                            //     <button
                            //         className="server__forms-control d-flex align-items-center justify-content-center flex-wrap light-blue-border radius10"
                            //         type="button"
                            //        >
                            //         <FontAwesomeIcon icon={solid("arrow-down")}/>
                            //     </button>
                            // </div>
                        )
                    )}

                </form>
            </div>

        </div>

    )
}

export default observer(AddRoom);