import AddDevice from "../controls/AddDevice";
import AddRoom from "../controls/AddRoom";
import DeviceListContainer from "./DeviceListContainer";
import {observer} from "mobx-react-lite";
import {useContext, useEffect, useState} from "react";
import RoomStore from "../store/RoomStore";
import {RoomDTO} from "../../api/types";
import ChangePassword from "../controls/ChangePassword";

const  userAreaRoom:RoomDTO = {id: -4, name: "User Area"};

function UserArea() {
    const roomStore = useContext(RoomStore);

    useEffect(() => {
        roomStore.selectedRoom = userAreaRoom;
    }, [roomStore]);

    return (
        <div className="auth">
            <div className="container d-flex align-items-center justify-content-center">
                <div className="row w100 justify-content-center">
                    <div className="col col-8 auth-wrap">
                        <div className="card">
                            <div className="card__header">
                                <h3>User Area</h3>
                            </div>
                            <ChangePassword/>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(UserArea);